body, html, #root, .app {
  height: 100%;
}

body {
  font-family: 'Press Start 2P', cursive;
  background: repeating-linear-gradient(to bottom, #0f0a1e, #0f0a1e 2px, #140e29 2px, #140e29 4px);
}

.content-container {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  clear: both;
  display: block;
}
.vs-logo {
  background-image: url('./vs.png');
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.combatants {
  position: relative;
  margin: 0 auto;
  clear: both;
  display: block;
  z-index: 10;
}

.combatant-full-height {
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
}

span#p1, span#p2 {
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: #FFF;
}

#p1pb > div, #p2pb > div {
  font-size:1.1em;
}

#p1pb , #p2pb  {
  background-color: #292929;
  border: 1px solid rgb(110, 110, 110);
  border-radius: 5px;
  height: 2.0em;
}

#p1pb .progress-bar, #p2pb .progress-bar {
  background-color: #aa0000;
}

.hpbar {
  width: 100%;
}

.match-container {
  color: white;
  width: 100%;
  grid-template: 3, 1fr;
}

.versus-image {
  text-align: center;
  display: block;
  padding: 1.0em;
}

.current-player {
  text-align: left;
}
.current-target {
  text-align: right;
}

.log-container {
  color: white;
  width: 100%;
  height: 200px;
  margin-top: 1.0em;
  display: block;
  justify-content: center;
  text-align: center;
  overflow-y: hidden;
}

.log-container .log-data {
  width: 60%;
  height: 100%;
  overflow-y: auto;
  word-wrap: normal;
  margin: 0 auto;
  border: 2px solid #fff;
}

.log-container .log-data .log-entry .text_atk,
.log-container .log-data .log-entry .text_dmg {
  font-size: 0.825em;
  font-weight: bold;
}

.log-container .log-data .log-entry:nth-child(odd) {
  background-color: rgb(0, 0, 0)
}

.log-container .log-data .log-entry:nth-child(even) {
  background-color: rgb(30, 30, 30)
}